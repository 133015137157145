import React from 'react';
import {WalletChatWidget} from 'react-wallet-chat'
import 'react-wallet-chat/dist/index.css'

const Home = () => {
return (
	<div>
	<div>
	<h1>Welcome to WalletChat!</h1>
	</div>
	<WalletChatWidget />
	</div>
);
};

export default Home;

